@charset "UTF-8";
/*!
 * Bootstrap Reboot v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/*!
 * Bootstrap Grid v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1210px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1410px;
  }
}

.container-fluid, .container-xs, .container-sm, .container-md, .container-rg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-xs {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-xs, .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 1024px) {
  .container, .container-xs, .container-sm, .container-md {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-xs, .container-sm, .container-md, .container-rg {
    max-width: 1210px;
  }
}

@media (min-width: 1600px) {
  .container, .container-xs, .container-sm, .container-md, .container-rg, .container-lg, .container-xl {
    max-width: 1410px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-rg-1, .col-rg-2, .col-rg-3, .col-rg-4, .col-rg-5, .col-rg-6, .col-rg-7, .col-rg-8, .col-rg-9, .col-rg-10, .col-rg-11, .col-rg-12, .col-rg,
.col-rg-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xs-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xs-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xs-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xs-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xs-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xs-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xs-first {
    order: -1;
  }
  .order-xs-last {
    order: 13;
  }
  .order-xs-0 {
    order: 0;
  }
  .order-xs-1 {
    order: 1;
  }
  .order-xs-2 {
    order: 2;
  }
  .order-xs-3 {
    order: 3;
  }
  .order-xs-4 {
    order: 4;
  }
  .order-xs-5 {
    order: 5;
  }
  .order-xs-6 {
    order: 6;
  }
  .order-xs-7 {
    order: 7;
  }
  .order-xs-8 {
    order: 8;
  }
  .order-xs-9 {
    order: 9;
  }
  .order-xs-10 {
    order: 10;
  }
  .order-xs-11 {
    order: 11;
  }
  .order-xs-12 {
    order: 12;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 8.33333%;
  }
  .offset-xs-2 {
    margin-left: 16.66667%;
  }
  .offset-xs-3 {
    margin-left: 25%;
  }
  .offset-xs-4 {
    margin-left: 33.33333%;
  }
  .offset-xs-5 {
    margin-left: 41.66667%;
  }
  .offset-xs-6 {
    margin-left: 50%;
  }
  .offset-xs-7 {
    margin-left: 58.33333%;
  }
  .offset-xs-8 {
    margin-left: 66.66667%;
  }
  .offset-xs-9 {
    margin-left: 75%;
  }
  .offset-xs-10 {
    margin-left: 83.33333%;
  }
  .offset-xs-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1024px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-rg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-rg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-rg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-rg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-rg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-rg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-rg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-rg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-rg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-rg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-rg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-rg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-rg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-rg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-rg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-rg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-rg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-rg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-rg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-rg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-rg-first {
    order: -1;
  }
  .order-rg-last {
    order: 13;
  }
  .order-rg-0 {
    order: 0;
  }
  .order-rg-1 {
    order: 1;
  }
  .order-rg-2 {
    order: 2;
  }
  .order-rg-3 {
    order: 3;
  }
  .order-rg-4 {
    order: 4;
  }
  .order-rg-5 {
    order: 5;
  }
  .order-rg-6 {
    order: 6;
  }
  .order-rg-7 {
    order: 7;
  }
  .order-rg-8 {
    order: 8;
  }
  .order-rg-9 {
    order: 9;
  }
  .order-rg-10 {
    order: 10;
  }
  .order-rg-11 {
    order: 11;
  }
  .order-rg-12 {
    order: 12;
  }
  .offset-rg-0 {
    margin-left: 0;
  }
  .offset-rg-1 {
    margin-left: 8.33333%;
  }
  .offset-rg-2 {
    margin-left: 16.66667%;
  }
  .offset-rg-3 {
    margin-left: 25%;
  }
  .offset-rg-4 {
    margin-left: 33.33333%;
  }
  .offset-rg-5 {
    margin-left: 41.66667%;
  }
  .offset-rg-6 {
    margin-left: 50%;
  }
  .offset-rg-7 {
    margin-left: 58.33333%;
  }
  .offset-rg-8 {
    margin-left: 66.66667%;
  }
  .offset-rg-9 {
    margin-left: 75%;
  }
  .offset-rg-10 {
    margin-left: 83.33333%;
  }
  .offset-rg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1400px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1600px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1024px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-rg-none {
    display: none !important;
  }
  .d-rg-inline {
    display: inline !important;
  }
  .d-rg-inline-block {
    display: inline-block !important;
  }
  .d-rg-block {
    display: block !important;
  }
  .d-rg-table {
    display: table !important;
  }
  .d-rg-table-row {
    display: table-row !important;
  }
  .d-rg-table-cell {
    display: table-cell !important;
  }
  .d-rg-flex {
    display: flex !important;
  }
  .d-rg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1400px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-xs-row {
    flex-direction: row !important;
  }
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xs-fill {
    flex: 1 1 auto !important;
  }
  .flex-xs-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xs-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xs-start {
    justify-content: flex-start !important;
  }
  .justify-content-xs-end {
    justify-content: flex-end !important;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .justify-content-xs-between {
    justify-content: space-between !important;
  }
  .justify-content-xs-around {
    justify-content: space-around !important;
  }
  .align-items-xs-start {
    align-items: flex-start !important;
  }
  .align-items-xs-end {
    align-items: flex-end !important;
  }
  .align-items-xs-center {
    align-items: center !important;
  }
  .align-items-xs-baseline {
    align-items: baseline !important;
  }
  .align-items-xs-stretch {
    align-items: stretch !important;
  }
  .align-content-xs-start {
    align-content: flex-start !important;
  }
  .align-content-xs-end {
    align-content: flex-end !important;
  }
  .align-content-xs-center {
    align-content: center !important;
  }
  .align-content-xs-between {
    align-content: space-between !important;
  }
  .align-content-xs-around {
    align-content: space-around !important;
  }
  .align-content-xs-stretch {
    align-content: stretch !important;
  }
  .align-self-xs-auto {
    align-self: auto !important;
  }
  .align-self-xs-start {
    align-self: flex-start !important;
  }
  .align-self-xs-end {
    align-self: flex-end !important;
  }
  .align-self-xs-center {
    align-self: center !important;
  }
  .align-self-xs-baseline {
    align-self: baseline !important;
  }
  .align-self-xs-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1024px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-rg-row {
    flex-direction: row !important;
  }
  .flex-rg-column {
    flex-direction: column !important;
  }
  .flex-rg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-rg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-rg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-rg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-rg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-rg-fill {
    flex: 1 1 auto !important;
  }
  .flex-rg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-rg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-rg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-rg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-rg-start {
    justify-content: flex-start !important;
  }
  .justify-content-rg-end {
    justify-content: flex-end !important;
  }
  .justify-content-rg-center {
    justify-content: center !important;
  }
  .justify-content-rg-between {
    justify-content: space-between !important;
  }
  .justify-content-rg-around {
    justify-content: space-around !important;
  }
  .align-items-rg-start {
    align-items: flex-start !important;
  }
  .align-items-rg-end {
    align-items: flex-end !important;
  }
  .align-items-rg-center {
    align-items: center !important;
  }
  .align-items-rg-baseline {
    align-items: baseline !important;
  }
  .align-items-rg-stretch {
    align-items: stretch !important;
  }
  .align-content-rg-start {
    align-content: flex-start !important;
  }
  .align-content-rg-end {
    align-content: flex-end !important;
  }
  .align-content-rg-center {
    align-content: center !important;
  }
  .align-content-rg-between {
    align-content: space-between !important;
  }
  .align-content-rg-around {
    align-content: space-around !important;
  }
  .align-content-rg-stretch {
    align-content: stretch !important;
  }
  .align-self-rg-auto {
    align-self: auto !important;
  }
  .align-self-rg-start {
    align-self: flex-start !important;
  }
  .align-self-rg-end {
    align-self: flex-end !important;
  }
  .align-self-rg-center {
    align-self: center !important;
  }
  .align-self-rg-baseline {
    align-self: baseline !important;
  }
  .align-self-rg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1400px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1600px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-xs-0 {
    margin: 0 !important;
  }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important;
  }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important;
  }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important;
  }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important;
  }
  .m-xs-1 {
    margin: 0.25rem !important;
  }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important;
  }
  .m-xs-2 {
    margin: 0.5rem !important;
  }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important;
  }
  .m-xs-3 {
    margin: 1rem !important;
  }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important;
  }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important;
  }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important;
  }
  .m-xs-4 {
    margin: 1.5rem !important;
  }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important;
  }
  .m-xs-5 {
    margin: 3rem !important;
  }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important;
  }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important;
  }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important;
  }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important;
  }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important;
  }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important;
  }
  .p-xs-1 {
    padding: 0.25rem !important;
  }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important;
  }
  .p-xs-2 {
    padding: 0.5rem !important;
  }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important;
  }
  .p-xs-3 {
    padding: 1rem !important;
  }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important;
  }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important;
  }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important;
  }
  .p-xs-4 {
    padding: 1.5rem !important;
  }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important;
  }
  .p-xs-5 {
    padding: 3rem !important;
  }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important;
  }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important;
  }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important;
  }
  .m-xs-n1 {
    margin: -0.25rem !important;
  }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xs-n2 {
    margin: -0.5rem !important;
  }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xs-n3 {
    margin: -1rem !important;
  }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1rem !important;
  }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1rem !important;
  }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1rem !important;
  }
  .m-xs-n4 {
    margin: -1.5rem !important;
  }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xs-n5 {
    margin: -3rem !important;
  }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -3rem !important;
  }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -3rem !important;
  }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -3rem !important;
  }
  .m-xs-auto {
    margin: auto !important;
  }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important;
  }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important;
  }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important;
  }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1024px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-rg-0 {
    margin: 0 !important;
  }
  .mt-rg-0,
  .my-rg-0 {
    margin-top: 0 !important;
  }
  .mr-rg-0,
  .mx-rg-0 {
    margin-right: 0 !important;
  }
  .mb-rg-0,
  .my-rg-0 {
    margin-bottom: 0 !important;
  }
  .ml-rg-0,
  .mx-rg-0 {
    margin-left: 0 !important;
  }
  .m-rg-1 {
    margin: 0.25rem !important;
  }
  .mt-rg-1,
  .my-rg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-rg-1,
  .mx-rg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-rg-1,
  .my-rg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-rg-1,
  .mx-rg-1 {
    margin-left: 0.25rem !important;
  }
  .m-rg-2 {
    margin: 0.5rem !important;
  }
  .mt-rg-2,
  .my-rg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-rg-2,
  .mx-rg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-rg-2,
  .my-rg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-rg-2,
  .mx-rg-2 {
    margin-left: 0.5rem !important;
  }
  .m-rg-3 {
    margin: 1rem !important;
  }
  .mt-rg-3,
  .my-rg-3 {
    margin-top: 1rem !important;
  }
  .mr-rg-3,
  .mx-rg-3 {
    margin-right: 1rem !important;
  }
  .mb-rg-3,
  .my-rg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-rg-3,
  .mx-rg-3 {
    margin-left: 1rem !important;
  }
  .m-rg-4 {
    margin: 1.5rem !important;
  }
  .mt-rg-4,
  .my-rg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-rg-4,
  .mx-rg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-rg-4,
  .my-rg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-rg-4,
  .mx-rg-4 {
    margin-left: 1.5rem !important;
  }
  .m-rg-5 {
    margin: 3rem !important;
  }
  .mt-rg-5,
  .my-rg-5 {
    margin-top: 3rem !important;
  }
  .mr-rg-5,
  .mx-rg-5 {
    margin-right: 3rem !important;
  }
  .mb-rg-5,
  .my-rg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-rg-5,
  .mx-rg-5 {
    margin-left: 3rem !important;
  }
  .p-rg-0 {
    padding: 0 !important;
  }
  .pt-rg-0,
  .py-rg-0 {
    padding-top: 0 !important;
  }
  .pr-rg-0,
  .px-rg-0 {
    padding-right: 0 !important;
  }
  .pb-rg-0,
  .py-rg-0 {
    padding-bottom: 0 !important;
  }
  .pl-rg-0,
  .px-rg-0 {
    padding-left: 0 !important;
  }
  .p-rg-1 {
    padding: 0.25rem !important;
  }
  .pt-rg-1,
  .py-rg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-rg-1,
  .px-rg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-rg-1,
  .py-rg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-rg-1,
  .px-rg-1 {
    padding-left: 0.25rem !important;
  }
  .p-rg-2 {
    padding: 0.5rem !important;
  }
  .pt-rg-2,
  .py-rg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-rg-2,
  .px-rg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-rg-2,
  .py-rg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-rg-2,
  .px-rg-2 {
    padding-left: 0.5rem !important;
  }
  .p-rg-3 {
    padding: 1rem !important;
  }
  .pt-rg-3,
  .py-rg-3 {
    padding-top: 1rem !important;
  }
  .pr-rg-3,
  .px-rg-3 {
    padding-right: 1rem !important;
  }
  .pb-rg-3,
  .py-rg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-rg-3,
  .px-rg-3 {
    padding-left: 1rem !important;
  }
  .p-rg-4 {
    padding: 1.5rem !important;
  }
  .pt-rg-4,
  .py-rg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-rg-4,
  .px-rg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-rg-4,
  .py-rg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-rg-4,
  .px-rg-4 {
    padding-left: 1.5rem !important;
  }
  .p-rg-5 {
    padding: 3rem !important;
  }
  .pt-rg-5,
  .py-rg-5 {
    padding-top: 3rem !important;
  }
  .pr-rg-5,
  .px-rg-5 {
    padding-right: 3rem !important;
  }
  .pb-rg-5,
  .py-rg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-rg-5,
  .px-rg-5 {
    padding-left: 3rem !important;
  }
  .m-rg-n1 {
    margin: -0.25rem !important;
  }
  .mt-rg-n1,
  .my-rg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-rg-n1,
  .mx-rg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-rg-n1,
  .my-rg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-rg-n1,
  .mx-rg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-rg-n2 {
    margin: -0.5rem !important;
  }
  .mt-rg-n2,
  .my-rg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-rg-n2,
  .mx-rg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-rg-n2,
  .my-rg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-rg-n2,
  .mx-rg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-rg-n3 {
    margin: -1rem !important;
  }
  .mt-rg-n3,
  .my-rg-n3 {
    margin-top: -1rem !important;
  }
  .mr-rg-n3,
  .mx-rg-n3 {
    margin-right: -1rem !important;
  }
  .mb-rg-n3,
  .my-rg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-rg-n3,
  .mx-rg-n3 {
    margin-left: -1rem !important;
  }
  .m-rg-n4 {
    margin: -1.5rem !important;
  }
  .mt-rg-n4,
  .my-rg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-rg-n4,
  .mx-rg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-rg-n4,
  .my-rg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-rg-n4,
  .mx-rg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-rg-n5 {
    margin: -3rem !important;
  }
  .mt-rg-n5,
  .my-rg-n5 {
    margin-top: -3rem !important;
  }
  .mr-rg-n5,
  .mx-rg-n5 {
    margin-right: -3rem !important;
  }
  .mb-rg-n5,
  .my-rg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-rg-n5,
  .mx-rg-n5 {
    margin-left: -3rem !important;
  }
  .m-rg-auto {
    margin: auto !important;
  }
  .mt-rg-auto,
  .my-rg-auto {
    margin-top: auto !important;
  }
  .mr-rg-auto,
  .mx-rg-auto {
    margin-right: auto !important;
  }
  .mb-rg-auto,
  .my-rg-auto {
    margin-bottom: auto !important;
  }
  .ml-rg-auto,
  .mx-rg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1400px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -40px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 15px;
  width: 15px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
  border: 2px solid #777676;
  border-radius: 50%;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button {
  border-width: 4px;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

@font-face {
  font-family: "ProximaNova";
  font-style: "normal";
  font-weight: 400;
  src: url("../fonts/ProximaNovaRegular/ProximaNovaRegular.eot?") format("eot"), url("../fonts/ProximaNovaRegular/ProximaNovaRegular.woff2") format("woff2"), url("../fonts/ProximaNovaRegular/ProximaNovaRegular.woff") format("woff"), url("../fonts/ProximaNovaRegular/ProximaNovaRegular.ttf") format("truetype"), url("../fonts/ProximaNovaRegular/ProximaNovaRegular.svg#ProximaNova") format("svg");
}

@font-face {
  font-family: "ProximaNova";
  font-style: "normal";
  font-weight: 700;
  src: url("../fonts/ProximaNovaBold/ProximaNovaBold.eot?") format("eot"), url("../fonts/ProximaNovaBold/ProximaNovaBold.woff2") format("woff2"), url("../fonts/ProximaNovaBold/ProximaNovaBold.woff") format("woff"), url("../fonts/ProximaNovaBold/ProximaNovaBold.ttf") format("truetype"), url("../fonts/ProximaNovaBold/ProximaNovaBold.svg#ProximaNova") format("svg");
}

@font-face {
  font-family: "ProximaNova";
  font-style: "normal";
  font-weight: 900;
  src: url("../fonts/ProximaNovaBlack/ProximaNovaBlack.eot?") format("eot"), url("../fonts/ProximaNovaBlack/ProximaNovaBlack.woff2") format("woff2"), url("../fonts/ProximaNovaBlack/ProximaNovaBlack.woff") format("woff"), url("../fonts/ProximaNovaBlack/ProximaNovaBlack.ttf") format("truetype"), url("../fonts/ProximaNovaBlack/ProximaNovaBlack.svg#ProximaNova") format("svg");
}

@font-face {
  font-family: "DinPro";
  font-style: "normal";
  font-weight: 400;
  src: url("../fonts/PFDinCondensedRegular/PFDinCondensedRegular.eot?") format("eot"), url("../fonts/PFDinCondensedRegular/PFDinCondensedRegular.woff2") format("woff2"), url("../fonts/PFDinCondensedRegular/PFDinCondensedRegular.woff") format("woff"), url("../fonts/PFDinCondensedRegular/PFDinCondensedRegular.ttf") format("truetype"), url("../fonts/PFDinCondensedRegular/PFDinCondensedRegular.svg#DinPro") format("svg");
}

@font-face {
  font-family: "DinPro";
  font-style: "normal";
  font-weight: 500;
  src: url("../fonts/PFDinCondensedMedium/PFDinCondensedMedium.eot?") format("eot"), url("../fonts/PFDinCondensedMedium/PFDinCondensedMedium.woff2") format("woff2"), url("../fonts/PFDinCondensedMedium/PFDinCondensedMedium.woff") format("woff"), url("../fonts/PFDinCondensedMedium/PFDinCondensedMedium.ttf") format("truetype"), url("../fonts/PFDinCondensedMedium/PFDinCondensedMedium.svg#DinPro") format("svg");
}

@font-face {
  font-family: "Gilroy";
  font-style: "normal";
  font-weight: 300;
  src: url("../fonts/Gilroy/Gilroy-Light.eot?") format("eot"), url("../fonts/Gilroy/Gilroy-Light.woff2") format("woff2"), url("../fonts/Gilroy/Gilroy-Light.woff") format("woff"), url("../fonts/Gilroy/Gilroy-Light.ttf") format("truetype"), url("../fonts/Gilroy/Gilroy-Light.svg#Gilroy") format("svg");
}

@font-face {
  font-family: "Gilroy";
  font-style: "normal";
  font-weight: 900;
  src: url("../fonts/Gilroy/Gilroy-Black.eot?") format("eot"), url("../fonts/Gilroy/Gilroy-Black.woff2") format("woff2"), url("../fonts/Gilroy/Gilroy-Black.woff") format("woff"), url("../fonts/Gilroy/Gilroy-Black.ttf") format("truetype"), url("../fonts/Gilroy/Gilroy-Black.svg#Gilroy") format("svg");
}

@font-face {
  font-family: "iconfont";
  src: url("../iconfont/iconfont.eot");
  src: url("../iconfont/iconfont.eot?#iefix") format("eot"), url("../iconfont/iconfont.woff2") format("woff2"), url("../iconfont/iconfont.woff") format("woff"), url("../iconfont/iconfont.ttf") format("truetype"), url("../iconfont/iconfont.svg#iconfont") format("svg");
}

.icon-bus:before, .icon-chat:before, .icon-cv:before, .icon-documents:before, .icon-fb:before, .icon-hands:before, .icon-like:before, .icon-mail-new:before, .icon-mail:before, .icon-offer:before, .icon-resume:before, .icon-tel:before, .icon-tg:before, .icon-viber:before, .icon-wh:before, .icon-work:before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  line-height: 1;
}

.icon-bus:before {
  content: "";
}

.icon-chat:before {
  content: "";
}

.icon-cv:before {
  content: "";
}

.icon-documents:before {
  content: "";
}

.icon-fb:before {
  content: "";
}

.icon-hands:before {
  content: "";
}

.icon-like:before {
  content: "";
}

.icon-mail-new:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-offer:before {
  content: "";
}

.icon-resume:before {
  content: "";
}

.icon-tel:before {
  content: "";
}

.icon-tg:before {
  content: "";
}

.icon-viber:before {
  content: "";
}

.icon-wh:before {
  content: "";
}

.icon-work:before {
  content: "";
}

* {
  box-sizing: border-box;
}

html,
body {
  -webkit-overflow-scrolling: touch;
}

html {
  font-size: 100%;
}

body,
button,
input {
  font-family: "ProximaNova";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  outline: none;
}

body {
  background: #fff;
  color: #313232;
}

body.overflow {
  overflow: hidden;
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

span.required {
  font-size: 13px !important;
  color: red !important;
}

.errormsg {
  display: block;
  line-height: 22px;
  color: red;
  margin: 10px 0;
}

label {
  margin-bottom: 0;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all 0.3s;
  background-color: rgba(56, 52, 40, 0.8);
}

@media (max-width: 575.98px) {
  .header {
    position: relative;
  }
}

.header.scroll {
  position: fixed;
  transition: all 0.3s;
  background-color: #4d4d4d;
}

.header-nav {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 99;
}

@media (max-width: 575.98px) {
  .header-nav {
    height: 50px;
  }
}

.header-nav__logo {
  font-size: 28px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 900;
  font-family: "Gilroy";
}

@media (max-width: 575.98px) {
  .header-nav__logo {
    font-size: 16px;
    line-height: 20px;
  }
}

.header-nav__logo:hover {
  text-decoration: none;
  color: #fff;
}

.header-nav__logo span {
  font-weight: 300;
  text-transform: lowercase;
}

.header-nav__control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-nav__control a {
  margin-right: 20px;
}

@media (max-width: 767.98px) {
  .header-nav__control a {
    display: none;
  }
}

.header-nav__control a:last-child {
  margin-right: 0;
}

.header-nav__social {
  position: relative;
  overflow: hidden;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  color: #fff;
  background-color: #777676;
  border-radius: 50px;
  font-size: 20px;
}

.header-nav__social::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: none;
  opacity: 0;
  z-index: -1;
  display: block;
  margin: auto;
  width: calc(100% + 150px);
  height: 0;
  color: transparent;
  background: #4d4d4d;
  transition: all .3s;
  transform: rotate(-30deg);
}

.header-nav__social:hover {
  color: #fff;
  text-decoration: none;
}

.header-nav__social:hover::before {
  opacity: 1;
  visibility: visible;
  height: calc(250% * 2);
  transition: all .3s;
}

.header-nav__social .icon {
  display: flex;
}

.header-nav__mail {
  color: #fff;
  font-size: 18px;
}

.header-nav__mail:hover {
  text-decoration: none;
  color: #fff;
}

.header-nav__mail .icon {
  top: 7px;
  position: relative;
  font-size: 24px;
}

.site-header__menu {
  position: absolute;
  left: 0;
  top: -300px;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -9;
  background-color: #4d4d4d;
  border-radius: 0% 0% 50% 50%;
  transition: all 0.8s;
}

@media (max-width: 1199.98px) {
  .site-header__menu {
    height: calc(100vh - 30px);
    max-width: 100%;
  }
}

.site-header__menu--open {
  transition: all 0.5s;
  top: 0;
  opacity: 1;
  z-index: 9;
  border-radius: 0;
  visibility: visible;
}

.site-header__menu-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.site-header__menu-list__item {
  text-align: center;
}

.site-header__menu-list__item:not(:last-child) {
  margin-bottom: 5px;
}

@media (max-width: 1199.98px) {
  .site-header__menu-list__item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.site-header__menu-list__item a {
  text-decoration: none;
  color: #fff;
  font-size: 34px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Gilroy";
  transition: 0.3s all;
}

@media (max-width: 1199.98px) {
  .site-header__menu-list__item a {
    font-size: 26px;
  }
}

.site-header__menu-list__item a:hover {
  opacity: 0.8;
  transition: 0.3s all;
}

.btn {
  position: relative;
  overflow: hidden;
  z-index: 9;
  padding: 15px 30px;
  display: inline-block;
  background-color: #777676;
  border-radius: 50px;
  border: none;
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
}

.btn--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: none;
  opacity: 0;
  z-index: -1;
  display: block;
  margin: auto;
  width: calc(100% + 200px);
  height: 0;
  color: transparent;
  background: #4d4d4d;
  transition: all .3s;
  transform: rotate(-30deg);
}

.btn:hover {
  text-decoration: none;
  color: #fff;
}

.btn:hover::before {
  opacity: 1;
  visibility: visible;
  height: calc(250% * 2);
  transition: all .3s;
}

.btn-text {
  padding: 0;
  background-color: transparent;
  color: #777676;
}

.btn-text:hover {
  color: #313232;
}

.btn-text::before {
  display: none;
}

.burger-button {
  position: relative;
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  z-index: 9;
  display: block;
}

.burger-button:focus {
  outline: 0;
}

.burger-button.d-sm-none {
  z-index: 2;
}

.burger-icon {
  width: 25px;
  height: 25px;
  position: relative;
  transform: rotate(0deg);
  transition: .25s ease-out;
  cursor: pointer;
  display: block;
}

.burger-icon__bar {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-out;
}

.burger-icon__bar:nth-child(1) {
  top: 3px;
}

.burger-icon__bar:nth-child(2), .burger-icon__bar:nth-child(3) {
  top: 10px;
}

.burger-icon__bar:nth-child(4) {
  top: 17px;
}

.burger-icon.burger-icon--open {
  position: relative;
}

.burger-icon.burger-icon--open .burger-icon__bar {
  background: #fff;
  opacity: 1;
}

.burger-icon.burger-icon--open .burger-icon__bar:nth-child(1) {
  top: 7px;
  width: 0%;
  left: 50%;
}

.burger-icon.burger-icon--open .burger-icon__bar:nth-child(2) {
  transform: rotate(45deg);
}

.burger-icon.burger-icon--open .burger-icon__bar:nth-child(3) {
  transform: rotate(-45deg);
}

.burger-icon.burger-icon--open .burger-icon__bar:nth-child(4) {
  top: 7px;
  width: 0%;
  left: 50%;
}

.section {
  position: relative;
  padding: 70px 0;
}

@media (max-width: 1199.98px) {
  .section {
    padding: 70px 0;
  }
}

.section-home-services {
  background-image: url(../img/bg-germany-map.jpg);
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 40%;
}

@media (max-width: 767.98px) {
  .section-home-services {
    background: transparent;
  }
}

.section-home-contacts, .section-home-benefites {
  background-color: #f3f3f3;
}

.section-home-numbers, .section-home-about {
  z-index: 2;
  background-image: url(../img/radius-all-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

@media (max-width: 767.98px) {
  .section-home-numbers, .section-home-about {
    background-size: cover;
  }
}

.section__title {
  position: relative;
  margin-bottom: 25px;
  width: max-content;
  font-family: "Gilroy";
  font-weight: 300;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 52px;
}

@media (max-width: 767.98px) {
  .section__title {
    font-size: 38px;
    line-height: 52px;
  }
}

@media (max-width: 575.98px) {
  .section__title {
    font-size: 34px;
    line-height: 38px;
  }
}

.section__title--default::before {
  display: none;
}

.section__title--center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.section__title--white {
  color: #fff;
}

@media (max-width: 767.98px) {
  .section__title--white {
    width: 100%;
    margin: 0 auto 25px auto;
    text-align: center;
  }
  .section__title--white::before {
    display: none;
  }
}

.section__text--bigger {
  font-size: 38px;
  line-height: 42px;
}

@media (max-width: 767.98px) {
  .section__text--bigger {
    font-size: 34px;
    line-height: 38px;
  }
}

@media (max-width: 575.98px) {
  .section__text--bigger {
    font-size: 30px;
    line-height: 34px;
  }
}

.section__text--center {
  text-align: center;
}

.section__text--title {
  margin-bottom: 0;
  font-family: "Gilroy";
  font-weight: 300;
}

.section__text--white {
  color: #fff;
}

@media (max-width: 767.98px) {
  .section__text--white {
    text-align: center;
  }
}

.services {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -15px;
  margin-right: 15px;
  margin-top: 60px;
}

.services .service__box {
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 1199.98px) {
  .services .service__box {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

.services .service__box .icon {
  display: flex;
  margin-bottom: 20px;
  color: #777676;
  font-size: 88px;
}

.contacts__mail {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid;
  color: #313232;
  font-size: 24px;
}

.contacts__mail .icon {
  display: inline-flex;
  margin-right: 5px;
  margin-top: 5px;
}

.contacts__mail:hover {
  color: initial;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}

.contacts-list {
  padding: 0;
  margin: 0;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}

@media (max-width: 1199.98px) {
  .contacts-list {
    flex-wrap: wrap;
  }
}

.contacts-list li:not(:last-child) {
  margin-right: 30px;
}

@media (max-width: 1199.98px) {
  .contacts-list li:not(:last-child) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 1199.98px) {
  .contacts-list li:not(:last-child):nth-child(2) {
    margin-left: 15px;
  }
}

@media (max-width: 575.98px) {
  .contacts-list li:not(:last-child):nth-child(2) {
    margin-left: 0;
  }
}

.contacts-list__btn {
  min-width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.contacts-list__btn.telegram {
  background-color: #0088cc;
}

.contacts-list__btn.skype {
  background-color: #00aff0;
}

.contacts-list__btn.messenger {
  background-color: #0078FF;
}

.contacts-list__btn::before {
  background-color: #2d74c0;
}

.contacts-list__btn .icon {
  display: inline-flex;
  margin-right: 10px;
  font-size: 18px;
}

.footer {
  padding: 15px 0;
  position: relative;
  background: #4d4d4d;
}

@media (max-width: 767.98px) {
  .footer {
    padding: 25px 0;
  }
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}

.footer__logo {
  font-size: 28px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 700;
}

.footer__logo:hover {
  text-decoration: none;
  color: #fff;
}

.footer__logo span {
  font-weight: 400;
}

.footer__social {
  position: relative;
  overflow: hidden;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  color: #fff;
  background-color: #777676;
  border-radius: 50px;
  font-size: 20px;
}

.footer__social::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: none;
  opacity: 0;
  z-index: -1;
  display: block;
  margin: auto;
  width: calc(100% + 150px);
  height: 0;
  color: transparent;
  background: #4d4d4d;
  transition: all .3s;
  transform: rotate(-30deg);
}

.footer__social:hover {
  color: #fff;
  text-decoration: none;
}

.footer__social:hover::before {
  opacity: 1;
  visibility: visible;
  height: calc(250% * 2);
  transition: all .3s;
}

.footer__social .icon {
  display: flex;
}

.footer__mail {
  color: #fff;
  font-size: 18px;
}

.footer__mail:hover {
  text-decoration: none;
  color: #fff;
}

.footer__mail .icon {
  top: 2px;
  position: relative;
  font-size: 14px;
  left: -2px;
}

.footer__control {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 1199.98px) {
  .footer__control {
    justify-content: center;
  }
}

.footer__control-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575.98px) {
  .footer__control-list {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}

.footer__control-list li:not(:last-child) {
  margin-right: 30px;
}

.footer__control a {
  margin-right: 20px;
}

.footer__control a:last-child {
  margin-right: 0;
}

.footer-copyright {
  text-align: center;
  color: #fff;
  margin-bottom: 0;
}

.slick-slide {
  padding: 0 15px;
}

.slick-reviews {
  margin-top: 50px;
}

.slick-reviews__slide {
  padding: 50px 30px;
  border-radius: 5px;
  background: #777676;
  background: linear-gradient(90deg, #777676 0%, #4d4d4d 100%);
}

.slick-reviews__slide p {
  margin-bottom: 0;
  color: #fff;
}

.slick-reviews .slide__title {
  margin-bottom: 10px;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.benefit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767.98px) {
  .benefit {
    justify-content: center;
  }
}

.benefit:not(:last-child) {
  margin-bottom: 50px;
}

.benefit-content {
  margin-right: 0px;
  flex: 0 0 235px;
  max-width: 235px;
}

@media (max-width: 767.98px) {
  .benefit-content {
    margin-left: 30px;
    margin-right: 0;
  }
}

.benefit-content--right {
  text-align: right;
}

@media (max-width: 767.98px) {
  .benefit-content--right {
    margin-right: 30px;
    margin-left: 0;
  }
}

.benefit-content p {
  font-size: 14px;
  margin-bottom: 0;
}

.benefit__title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
}

.benefit .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  font-size: 40px;
  border-radius: 50%;
  background-color: #777676;
  color: #fff;
}

@media (max-width: 1199.98px) {
  .benefit .icon {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
}

.benefit__icon {
  padding: 15px;
  border-radius: 50%;
  border: 1px solid #777676;
}

.number-box {
  text-align: center;
  color: #fff;
}

@media (max-width: 767.98px) {
  .number-box {
    margin-bottom: 30px;
  }
}

.number-box p {
  margin-bottom: 0;
  font-size: 20px;
  line-height: 26px;
}

.number-box__num {
  position: relative;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 205px;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
}

@media (max-width: 767.98px) {
  .number-box__num {
    max-width: 160px;
    min-height: 160px;
    margin: 0 auto 15px auto;
    font-size: 16px;
  }
}

.number-box__num::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: block;
  border-radius: 50%;
}

.number-box__num#percente::before {
  border-right: 4px solid #fff;
  right: -2px;
}

.number-box__num#year::before {
  border-top: 4px solid #fff;
  left: -2px;
  top: -2px;
}

.number-box__num#day::before {
  border-bottom: 4px solid #fff;
  bottom: -2px;
  right: -2px;
}

.number-box__num span {
  display: block;
  font-family: "DinPro";
  font-size: 70px;
  font-weight: bold;
  line-height: 70px;
}

@media (max-width: 767.98px) {
  .number-box__num span {
    font-size: 50px;
    line-height: 50px;
  }
}

.popup {
  position: relative;
  padding: 50px;
  width: 100%;
  max-width: 420px;
  margin: 30px auto;
  border-radius: 3px;
  background: #ffffff;
}

.popup-content {
  text-align: center;
}

.popup-content__title {
  font-family: "DinPro";
  text-transform: uppercase;
  font-size: 42px;
  text-transform: uppercase;
  font-weight: bold;
}

.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.85 !important;
  background-color: #232323;
}

/**
 * Fade-zoom animation for first dialog
 */
/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  transform: scale(0.8);
  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.form__input {
  width: 100%;
  border-radius: 50px;
  border: 1px solid #e3e3e3;
  padding: 10px 30px;
}

.form__input:focus {
  border: 1px solid #777676;
}

.form-page {
  max-width: 50%;
  margin: 0 auto;
}

@media (max-width: 575.98px) {
  .form-page {
    max-width: 100%;
  }
}

.offer {
  margin-bottom: 60px;
}

.offer__content {
  margin-top: 15px;
}

.offer__thumb {
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer__title {
  color: #777676;
  font-size: 20px;
}

.offer__list {
  padding-left: 15px;
  margin-bottom: 0;
}

.offer__list li {
  font-size: 14px;
}

.offer__list li span {
  font-weight: 700;
}

.need {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

@media (max-width: 1023.98px) {
  .need {
    display: block;
    margin-bottom: 60px;
  }
}

.need span {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  font-size: 50px;
  font-weight: 900;
  opacity: 0.3;
}

.need p {
  margin-bottom: 15px;
  position: relative;
  z-index: 2;
  font-size: 14px;
  text-align: center;
}

@media (max-width: 1023.98px) {
  .need p {
    margin-bottom: 0;
  }
}

.need__img {
  z-index: -1;
}

@media (max-width: 1023.98px) {
  .need__img {
    max-width: 220px;
    margin: 0 auto;
  }
}

#need {
  padding-bottom: 0px;
}

@media (max-width: 1023.98px) {
  #need {
    padding-bottom: 70px;
  }
}

.steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  flex-direction: column;
}

.steps .step {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 25%;
  max-width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  transform: translateX(70%);
}

.steps .step:not(:last-child) {
  margin-bottom: 50px;
}

@media (max-width: 575.98px) {
  .steps .step:not(:last-child) {
    margin-bottom: 0;
  }
}

.steps .step:not(:last-child) span::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  bottom: 0;
  z-index: -1;
  width: 1px;
  height: 100%;
  margin: auto;
  background-color: #4d4d4d;
}

.steps .step:nth-child(odd) {
  transform: translateX(-70%);
  text-align: right;
}

@media (max-width: 575.98px) {
  .steps .step:nth-child(odd) {
    transform: translateX(0%);
  }
}

.steps .step:nth-child(odd) span {
  transform: translateX(70%);
}

@media (max-width: 1023.98px) {
  .steps .step {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media (max-width: 575.98px) {
  .steps .step {
    flex: 0 0 100%;
    max-width: 100%;
    transform: translateX(0%);
  }
}

.steps .step span {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gilroy";
  font-size: 14px;
  font-weight: 600;
  z-index: 2;
  color: #fff;
  transform: translateX(-70%);
}

@media (max-width: 575.98px) {
  .steps .step span {
    display: none;
  }
}

.steps .step span::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 70px;
  height: 70px;
  margin: auto;
  background-color: #4d4d4d;
  transform: rotate(45deg);
  border: 1px solid #4d4d4d;
}

@media (max-width: 575.98px) {
  .steps .step span::after {
    width: 50px;
    height: 50px;
  }
}

.home-banner {
  position: relative;
  background-image: url("../img/home-bg-main-text.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  margin-top: 0px;
}

@media (max-width: 767.98px) {
  .home-banner {
    margin-top: 0px;
  }
}

.home-banner__content {
  position: relative;
  z-index: 1;
  height: 100vh;
  max-width: 420px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 1199.98px) {
  .home-banner__content {
    max-width: 100%;
    min-height: 28vh;
    height: auto;
  }
}

.home-banner__title {
  position: relative;
  margin-bottom: 40px;
  font-family: "DinPro";
  color: #4d4d4d;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 58px;
  line-height: 70px;
}

@media (max-width: 575.98px) {
  .home-banner__title {
    font-size: 48px;
    line-height: 60px;
  }
}

.home-banner__title span {
  display: block;
  font-size: 68px;
}

@media (max-width: 575.98px) {
  .home-banner__title span {
    font-size: 58px;
  }
}

.home-banner__title::before {
  content: '';
  position: relative;
  left: 0;
  top: -40px;
  display: block;
  height: 8px;
  width: 40px;
  background-color: #777676;
}

.home-banner__text {
  margin-bottom: 80px;
  font-size: 26px;
  line-height: 32px;
  color: #fff;
}
