.footer{
    padding: 15px 0;
    position: relative;
    background: #4d4d4d;
    // background: linear-gradient(90deg, #777676 0%, #4d4d4d 100%);

    @include media-breakpoint-down(xs){
        padding: 25px 0;
    }

    &-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;
    }

    &__logo{
        font-size: 28px;
        letter-spacing: 1px;
        color: $color-white;
        font-weight: 700;

        &:hover{
            text-decoration: none;
            color: $color-white;
        }

        span{
            font-weight: 400;
        }
    }

    &__social{
        position: relative;
        overflow: hidden;
        z-index: 9;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;

        color: $color-white;
        background-color: $btn-color;
        border-radius: 50px;
        font-size: 20px;

        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            visibility: none;
            opacity: 0;
            z-index: -1;
    
            display: block;
            margin: auto;
            width: calc(100% + 150px);
            height: 0;
    
            color: transparent;
            background: $color-hover;
            transition: all .3s;
            transform: rotate(-30deg);
        }

        &:hover{
            color: $color-white;
            text-decoration: none;

            &::before {
                opacity: 1;
                visibility: visible;
                height: calc(250% * 2);
                transition: all .3s;
            }
        }

        .icon{
            display: flex;
        }
    }

    &__mail{
        color: $color-white;
        font-size: 18px;

        &:hover{
            text-decoration: none;
            color: $color-white;
        }

        .icon{
            top: 2px;
            position: relative;
            font-size: 14px;
            left: -2px;
        }
    }

    &__control{
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include media-breakpoint-down(md){
            justify-content: center;
        }

        &-list{
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(tn){
                flex-wrap: wrap;
                margin-bottom: 30px;
            }

            li{

                &:not(:last-child){
                    margin-right: 30px;

                    @include media-breakpoint-down(tn){
                        // margin-right: 0;
                        // margin-bottom: 10px;
                    }
                }
            }
        }

        a{
            margin-right: 20px;

            &:last-child(){
                margin-right: 0;
            }
        }
    }

    &-copyright{
        text-align: center;
        color: $color-white;

        margin-bottom: 0;
    }
}