// Colors
$body-background-color: #fff;
$font-regular-color: #313232;
// $color-accent: #23b783d9;
$color-accent: #777676;
$color-white: #fff;
// $btn-color: #20c997;
$btn-color: #777676;
// $color-hover: #006b45;
$color-hover: #4d4d4d;

// Fonts
$font-regular: 'ProximaNova';
$font-second: 'DinPro';
$font-logo: 'Gilroy';