.need{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    @include media-breakpoint-down(sm){
        display: block;
        margin-bottom: 60px;
    }

    span{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 0;
        font-size: 50px;
        font-weight: 900;
        opacity: 0.3;
    }

    p{
        margin-bottom: 15px;  
        position: relative;
        z-index: 2;
        font-size: 14px;
        text-align: center;

        @include media-breakpoint-down(sm){
            margin-bottom: 0;
        }
    }

    &__img{
        // position: absolute;
        // left: 0;
        // right: 0;
        // bottom: 0;
        z-index: -1;
        // transform: translateY(100%);

        @include media-breakpoint-down(sm){
            max-width: 220px;
            margin: 0 auto;
        }
    }
}

#need{
    padding-bottom: 0px;

    @include media-breakpoint-down(sm){
        padding-bottom: 70px;
    }
}