.btn{
    position: relative;
    overflow: hidden;
    z-index: 9;

    padding: 15px 30px;
    display: inline-block;

    background-color: $btn-color;
    border-radius: 50px;
    border: none;

    text-transform: uppercase;
    color: $color-white;
    font-weight: 400;

    &--center{
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    // border: 1px solid transparent;

    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        visibility: none;
        opacity: 0;
        z-index: -1;

        display: block;
        margin: auto;
        width: calc(100% + 200px);
        height: 0;

        color: transparent;
        background: #4d4d4d;
        transition: all .3s;
        transform: rotate(-30deg);
    }

    &:hover{
        text-decoration: none;
        color: $color-white;
        // border: 2px solid $color-accent;

        &::before {
            opacity: 1;
            visibility: visible;
            height: calc(250% * 2);
            transition: all .3s;
        }
    }

    &-text{
        padding: 0;
        background-color: transparent;
        color: $color-accent;

        &:hover{
            color: $font-regular-color;
        }

        &::before{
            display: none;
        }
    }
}