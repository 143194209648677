.steps{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;
    flex-wrap: wrap;
    flex-direction: column;

    .step{
        position: relative;

        padding-left: 15px;
        padding-right: 15px;
        flex: 0 0 25%;
        max-width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100px;
        text-align: left;
        font-size: 16px;
        line-height: 22px;

        transform: translateX(70%);
        
        &:not(:last-child){
            margin-bottom: 50px;

            @include media-breakpoint-down(tn){
                margin-bottom: 0;
            }

            span::before{
                content: '';

                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                bottom: 0;
                z-index: -1;

                width: 1px;
                height: 100%;
                margin: auto;

                background-color: #4d4d4d;
            }
        }

        &:nth-child(odd){
            transform: translateX(-70%);
            text-align: right;
            @include media-breakpoint-down(tn){
                transform: translateX(0%);
            }
            span{
                transform: translateX(70%);
            }
        }

        @include media-breakpoint-down(sm){
            flex: 0 0 40%;
            max-width: 40%;
        }

        @include media-breakpoint-down(tn){
            flex: 0 0 100%;
            max-width: 100%;
            transform: translateX(0%);
        }

        span{
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: $font-logo;
            font-size: 14px;
            font-weight: 600;
            z-index: 2;
            color: $color-white;
            // opacity: 0.3;
            transform: translateX(-70%);

            @include media-breakpoint-down(tn){
                display: none;
            }

            &::after{
                content: '';

                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: -1;

                width: 70px;
                height: 70px;
                margin: auto;

                background-color: #4d4d4d;
                transform: rotate(45deg);
                border: 1px solid #4d4d4d;

                @include media-breakpoint-down(tn){
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }
}