.section{
    position: relative;

    padding: 70px 0;
    // min-height: 100vh;

    @include media-breakpoint-down(md){
        padding: 70px 0;
    }

    &-home{

        &-services{
            background-image: url(../img/bg-germany-map.jpg);
            background-repeat: no-repeat;
            background-position: 90% center;
            background-size: 40%;

            @include media-breakpoint-down(xs){
                background: transparent;
            }
        }
        
        &-contacts,&-benefites{
            background-color: #f3f3f3;
        }

        &-numbers, &-benefites{
            // margin-bottom: -60px;

            @include media-breakpoint-down(xs){
                // margin-bottom: 0;
            }
        }

        &-benefites{
            // padding-top: 160px;

            @include media-breakpoint-down(xs){
                // padding-top: 70px;
            }
        }

        &-numbers, &-about{
            z-index: 2;
            background-image: url(../img/radius-all-bg.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;

            @include media-breakpoint-down(xs){
                background-size: cover;
            }

            // .section__title{

            //     &::before{
            //         right: -60px;
            //         top: 28px;
            //         bottom: auto;

            //         background-color: $color-white;
            //     }
            // }
        }
    }
    
    &__title{
        position: relative;

        margin-bottom: 25px;
        width: max-content;

        font-family: $font-logo;
        font-weight: 300;
        font-size: 40px;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 52px;

        @include media-breakpoint-down(xs){
            font-size: 38px;
            line-height: 52px;
        }

        @include media-breakpoint-down(tn){
            font-size: 34px;
            line-height: 38px;
        }
        
        // &::before{
        //     content: '';
            
        //     position: absolute;
        //     right: -50px;
        //     top: 60px;
        //     bottom: 0;

        //     display: block;
        //     height: 4px;
        //     width: 100px;
        //     margin: auto;

        //     background-color: $btn-color;
        // }

        &--default{

            &::before{
                display: none;
            }
        }

        &--center{
            margin-left: auto;
            margin-right: auto;

            text-align: center;
        }

        &--white{
            color: $color-white;

            @include media-breakpoint-down(xs){
                width: 100%;
                margin: 0 auto 25px auto;
                text-align: center;

                &::before{
                    display: none;
                }
            }
        }
    }

    &__text{

        &--bigger{
            font-size: 38px;
            line-height: 42px;

            @include media-breakpoint-down(xs){
                font-size: 34px;
                line-height: 38px;
            }

            @include media-breakpoint-down(tn){
                font-size: 30px;
                line-height: 34px;
            }
        }

        &--center{
            text-align: center;
        }

        &--title{
            margin-bottom: 0;

            // font-family: $font-second;
            font-family: $font-logo;
            font-weight: 300;
        }

        &--white{
            color: $color-white;

            @include media-breakpoint-down(xs){
                text-align: center;
            }
        }
    }
}