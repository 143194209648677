// Burger

.burger-button {
    position: relative;
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    z-index: 9;
    display: block;

    &:focus {
        outline: 0;
    }

    &.d-sm-none{
        z-index: 2;
    }
}

.burger-icon {
    $root: &;

    width: 25px;
    height: 25px;
    position: relative;
    transform: rotate(0deg);
    transition: .25s ease-out;
    cursor: pointer;
    display: block;

    &__bar {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #fff;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-out;

        &:nth-child(1) {
            top: 3px;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 10px;
        }

        &:nth-child(4) {
            top: 17px;
        }
    }

    &.burger-icon--open {
        position: relative;

        .burger-icon__bar {
            background: $color-white;
            opacity: 1;

            &:nth-child(1) {
                top: 7px;
                width: 0%;
                left: 50%;
            }

            &:nth-child(2) {
                transform: rotate(45deg);

            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 7px;
                width: 0%;
                left: 50%;
            }
        }
    }
}
