* {
    box-sizing: border-box;
}

html,
body {
    -webkit-overflow-scrolling: touch;
}

html {
    font-size: 100%;
}

body,
button,
input {
    font-family: $font-regular;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    outline: none;
}

body {
    background: $body-background-color;
    color: $font-regular-color;

    &.overflow{
        overflow: hidden;
        height: 100%;
    }
}

img {
    max-width: 100%;
    height: auto;
}

a{
    // transition: all 0.3s;
    
    &:hover{
        //transition: all 0.3s;
    }
}

.text{
    
    &-center{
        text-align: center;
    }
}

span.required{
    font-size: 13px !important;
    color: red !important;
}

.errormsg {
display: block;
line-height: 22px;
color: red;
margin: 10px 0;
}

label{
    margin-bottom: 0;
}