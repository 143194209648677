.number{

    &-box{
        text-align: center;
        color: $color-white;

        @include media-breakpoint-down(xs){
            
            margin-bottom: 30px;
        }

        p{
            margin-bottom: 0;

            font-size: 20px;
            line-height: 26px;
        }

        &__num{
            position: relative;

            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-height: 205px;

            border-radius: 100px;
            border: 1px solid rgba(255, 255, 255, 0.5);
            font-size: 20px;

            @include media-breakpoint-down(xs){
                max-width: 160px;
                min-height: 160px;
                margin: 0 auto 15px auto;

                font-size: 16px;
            }

            &::before{
                content: '';

                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;

                display: block;

                border-radius: 50%;
                // border-top: 4px solid $color-white;
            }

            &#percente{

                &::before{
                    border-right: 4px solid $color-white;
                    right: -2px;
                    // border-left: 4px solid $color-white;
                }
            }

            &#year{

                &::before{
                    border-top: 4px solid $color-white;
                    // border-left: 4px solid $color-white;
                    left: -2px;
                    top: -2px;
                }
            }

            &#day{

                &::before{
                    // border-right: 4px solid $color-white;
                    border-bottom: 4px solid $color-white;
                    bottom: -2px;
                    right: -2px;
                }
            }
            
            span{
                display: block;

                font-family: $font-second;
                font-size: 70px;
                font-weight: bold;
                line-height: 70px;

                @include media-breakpoint-down(xs){
                    font-size: 50px;
                    line-height: 50px;
                }
            }
        }
    }
}