.slick{

    &-slide{
        padding: 0 15px;
    }

    &-reviews{
        margin-top: 50px;

        &__slide{
            padding: 50px 30px;

            border-radius: 5px;
            background: #777676;
            background: linear-gradient(90deg, #777676 0%, #4d4d4d 100%);

            p{
                margin-bottom: 0;
                
                color: $color-white;
            }
        }

        .slide{

            &__title{
                margin-bottom: 10px;

                font-size: 20px;
                color: $color-white;
                font-weight: bold;
                text-align: center;
            }
        }
    }
}