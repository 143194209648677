.header{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    z-index: 999;
    transition: all 0.3s;
    background-color: rgba(56, 52, 40, 0.8);

    @include media-breakpoint-down(tn){
        position: relative;
    }

    &.scroll{
        position: fixed;

        transition: all 0.3s;
        background-color: #4d4d4d;
    }

    &-nav{
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 99;

        @include media-breakpoint-down(tn){
            height: 50px;
        }

        &__logo{
            font-size: 28px;
            letter-spacing: 1px;
            color: $color-white;
            font-weight: 900;
            font-family: $font-logo;
            // text-transform: uppercase;

            @include media-breakpoint-down(tn){
                font-size: 16px;
                line-height: 20px;
            }

            &:hover{
                text-decoration: none;
                color: $color-white;
            }

            span{
                font-weight: 300;
                text-transform: lowercase;
            }
        }

        &__control{
            display: flex;
            align-items: center;
            justify-content: space-between;

            a{
                margin-right: 20px;

                @include media-breakpoint-down(xs){
                    display: none;
                }

                &:last-child(){
                    margin-right: 0;
                }
            }
        }

        &__social{
            position: relative;
            overflow: hidden;
            z-index: 9;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 38px;
            height: 38px;

            color: $color-white;
            background-color: $btn-color;
            border-radius: 50px;
            font-size: 20px;

            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                visibility: none;
                opacity: 0;
                z-index: -1;
        
                display: block;
                margin: auto;
                width: calc(100% + 150px);
                height: 0;
        
                color: transparent;
                background: $color-hover;
                transition: all .3s;
                transform: rotate(-30deg);
            }

            &:hover{
                color: $color-white;
                text-decoration: none;

                &::before {
                    opacity: 1;
                    visibility: visible;
                    height: calc(250% * 2);
                    transition: all .3s;
                }
            }

            .icon{
                display: flex;
            }
        }

        &__mail{
            color: $color-white;
            font-size: 18px;

            &:hover{
                text-decoration: none;
                color: $color-white;
            }

            .icon{
                top: 7px;
                position: relative;

                font-size: 24px;
            }
        }
    }
}

.site-header__menu{
    position: absolute;
    left: 0;
    top: -300px;
    right: 0;
    bottom: 0;
    
    visibility: hidden;
    opacity: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -9;
    // opacity: 0.85!important;
    background-color: #4d4d4d;
    border-radius: 0% 0% 50% 50%;
    transition: all 0.8s;

    @include media-breakpoint-down(md){
        height: calc(100vh - 30px);
        max-width: 100%;
    }

    &--open{
        transition: all 0.5s;
        top: 0;
        opacity: 1;
        z-index: 9;
        border-radius: 0;
        visibility: visible;
    }

    &-list{
        padding: 0;
        margin: 0;

        list-style: none;

        &__item{
            text-align: center;
            
            &:not(:last-child){
                margin-bottom: 5px;

                @include media-breakpoint-down(md){
                    margin-bottom: 10px;
                }
            }

            a{
                text-decoration: none;
                color: #fff;
                // font-weight: bold;
                font-size: 34px;
                letter-spacing: 2px;
                text-transform: uppercase;
                font-family: $font-logo;
                transition: 0.3s all;

                @include media-breakpoint-down(md){
                    font-size: 26px;
                }

                &:hover{
                    opacity: 0.8;
                    transition: 0.3s all;
                }
            }
        }
    }
}