.benefit{
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(xs){
        justify-content: center;
    }

    &:not(:last-child){
        margin-bottom: 50px;
    }

    &-content{
        margin-right: 0px;
        flex: 0 0 235px;
        max-width: 235px;

        @include media-breakpoint-down(xs){
            margin-left: 30px;
            margin-right: 0;
        }

        &--right{
            text-align: right;

            @include media-breakpoint-down(xs){
                margin-right: 30px;
                margin-left: 0;
            }
        }

        p{
            font-size: 14px;

            margin-bottom: 0;
        }
    }

    &__title{
        margin-bottom: 10px;

        font-weight: bold;
        font-size: 18px;
    }

    .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;

        font-size: 40px;
        border-radius: 50%;
        background-color: $color-accent;
        color: $color-white;

        @include media-breakpoint-down(md){
            width: 50px;
            height: 50px;

            font-size: 20px;
        }
    }

    &__icon{
        padding: 15px;

        border-radius: 50%;
        border: 1px solid $color-accent;
    }
}