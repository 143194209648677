.services{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: -15px;
    margin-right: 15px;
    margin-top: 60px;

    .service{

        &__box{
            flex: 0 0 20%;
            max-width: 20%;
            padding-left: 15px;
            padding-right: 15px;

            @include media-breakpoint-down(md){
                flex: 0 0 33.3333%;
                max-width: 33.3333%;
            }    

            .icon{
                display: flex;
                margin-bottom: 20px;

                color: $color-accent;
                font-size: 88px;
            }
        }
    }
}