.form{

    &__input{
        width: 100%;
        border-radius: 50px;
        border: 1px solid #e3e3e3;
        padding: 10px 30px;

        &:focus{
            border: 1px solid $color-accent;
        }
    }

    &-page{
        max-width: 50%;
        margin: 0 auto;

        @include media-breakpoint-down(tn){
            max-width: 100%;
        }
    }
}