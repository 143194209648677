.contacts{
    
    &__mail{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        width: max-content;
        margin-left: auto;
        margin-right: auto;

        border-bottom: 1px solid;

        color: #313232;
        font-size: 24px;

        .icon{
            display: inline-flex;
            margin-right: 5px;
            margin-top: 5px;
        }

        &:hover{
            color: initial;
            text-decoration: none;
            border-bottom: 1px solid transparent;
        }
    }

    &-list{
        padding: 0;
        margin: 0;
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        list-style: none;

        @include media-breakpoint-down(md){
            flex-wrap: wrap;
        }

        li{
            &:not(:last-child){
                margin-right: 30px;

                @include media-breakpoint-down(md){
                    margin-right: 0;
                    margin-bottom: 15px;
                }

                &:nth-child(2){

                    @include media-breakpoint-down(md){
                        margin-left: 15px;
                    }

                    @include media-breakpoint-down(tn){
                        margin-left: 0;
                    }
                }
            }
        }

        &__btn{
            min-width: 220px;
            display: flex;
            align-items: center;
            justify-content: center;

            text-align: center;

            &.telegram{
                background-color: #0088cc;
            }

            &.skype{
                background-color: #00aff0;
            }

            &.messenger{
                background-color: #0078FF;
            }

            &::before{
                background-color: #2d74c0;
            }

            .icon{
                display: inline-flex;
                margin-right: 10px;

                font-size: 18px;
            }
        }
    }
}