// @import 'iconfont';
@import 'mixins';

@include font-face('ProximaNova', '../fonts/ProximaNovaRegular/ProximaNovaRegular', 400, 'normal');
@include font-face('ProximaNova', '../fonts/ProximaNovaBold/ProximaNovaBold', 700, 'normal');
@include font-face('ProximaNova', '../fonts/ProximaNovaBlack/ProximaNovaBlack', 900, 'normal');

@include font-face('DinPro', '../fonts/PFDinCondensedRegular/PFDinCondensedRegular', 400, 'normal');
@include font-face('DinPro', '../fonts/PFDinCondensedMedium/PFDinCondensedMedium', 500, 'normal');

@include font-face('Gilroy', '../fonts/Gilroy/Gilroy-Light', 300, 'normal');
@include font-face('Gilroy', '../fonts/Gilroy/Gilroy-Black', 900, 'normal');