.offer{
    margin-bottom: 60px;

    &__content{
        margin-top: 15px;
    }

    &__thumb{
        min-height: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title{
        color: $color-accent;
        font-size: 20px;
    }

    &__list{
        padding-left: 15px;
        margin-bottom: 0;

        li{
            font-size: 14px;

            span{
                font-weight: 700;
            }
        }
    }
}