
// Modal offer end
.popup{
    position: relative;

    padding: 50px;
    width: 100%;
    max-width: 420px;
    margin: 30px auto;

    border-radius: 3px;

    background: #ffffff;

    &-content{
        text-align: center;
        
        &__title{
            font-family: $font-second;
            text-transform: uppercase;
            font-size: 42px;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
}

.my-mfp-zoom-in.mfp-ready.mfp-bg{
    opacity: 0.85!important;
    background-color: #232323;
}

/**
 * Fade-zoom animation for first dialog
 */

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
	opacity: 0;

	transition: all 0.2s ease-in-out; 
	transform: scale(0.8); 
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
    opacity: 1;
     
	transform: scale(1); 
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
	transform: scale(0.8); 

	opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
	opacity: 0;
	transition: opacity 0.3s ease-out;
}
/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.8;
}
/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0;
}
