.home-banner{
    position: relative;

    // background: rgb(0,107,69);
    // background: linear-gradient(90deg, rgba(0,107,69,1) 0%, rgba(35,183,131,1) 100%);
    // background-image: url(../img/5.jpg);
    background-image: url('../img/home-bg-main-text.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    margin-top: 0px;

    @include media-breakpoint-down(xs){
        // background-size: cover;
        margin-top: 0px;
    }
    

    // &::after{
    //     content: '';

    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     bottom: 0;
    //     right: 0;

    //     display: block;
    //     z-index: 0;

    //     background-image: url(../img/bg-image-new.png);
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     background-size: contain;
    // }

    &__content{
        position: relative;
        z-index: 1;
        
        // height: calc(100vh - 60px);
        height: 100vh;
        max-width: 420px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-down(md){
            // height: calc(100vh - 30px);
            max-width: 100%;
            min-height: 28vh;
            height: auto;
        }
    }

    &__title{
        position: relative;

        margin-bottom: 40px;

        font-family: $font-second;
        color: $color-hover;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 58px;
        line-height: 70px;

        @include media-breakpoint-down(tn){
            font-size: 48px;
            line-height: 60px;
        }

        span{
            display: block;

            font-size: 68px;

            @include media-breakpoint-down(tn){
                font-size: 58px;
            }
        }

        &::before{
            content: '';
            
            position: relative;
            left: 0;
            top: -40px;

            display: block;
            height: 8px;
            width: 40px;

            background-color: $btn-color;
        }
    }

    &__text{
        margin-bottom: 80px;

        font-size: 26px;
        line-height: 32px;
        color: $color-white;
    }
}